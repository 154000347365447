<template>
  <div class="row saved-param">
    <div class="col-4">
      {{ type }}
    </div>
    <div class="col-7">
      {{ value }}
    </div>
    <div class="col-1">
      <a
        href="javascript:void(0)"
        @click.prevent="deleteParam"
        title="Supprimer le paramètre de filtrage"
      >
        <i
          class="icofont icofont-close"
          style="color: #BB1B1B"
        />
      </a>
    </div>
  </div>
</template>

<script>
import { LIST_FILTER, TYPE_ESPECE, TYPE_RACE, TYPE_TYPE_USER } from '../../../constants/group';
import { mapGetters } from 'vuex';
import { DELETE_GROUP_PARAM } from '../../../graphql/group';
export default {
    props: {
        param: {type: Object, required: true}
    },
    methods: {
        deleteParam(){
            this.$apollo.mutate({
              mutation: DELETE_GROUP_PARAM,
              variables: {
                "uid": this.param.uid
              },
              update: (store, { data  }) => {
                  console.log(data)
              },
          }).then(({data}) => {
              console.log(data)
          }).catch((error) => {
              console.error(error)
          })
        }
    },
    computed: {
        ...mapGetters({
            typeComptes: 'auth/typeComptes',
            especes: 'identification/especes',
            races: 'identification/races'
        }),
        type(){
            let list = LIST_FILTER()
            return list.find(item => item.type === this.param.type).libelle
        },
        value(){
            switch(this.param.type){
                case TYPE_TYPE_USER: 
                    return [...this.typeComptes].find(item => item.uid === this.param.value).libelle
                case TYPE_ESPECE:
                    return [...this.especes].find(item => item.uid === this.param.value).libelle
                case TYPE_RACE:
                    return [...this.races].find(item => item.uid === this.param.value).libelle
            }
            return this.param.value
        }
    }

}
</script>

<style lang="scss" scoped>
.saved-param{
    padding: 20px;
    &:hover{
        background: #dfdfdf
    }
}
</style>