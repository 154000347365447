<template>
  <div
    class="d-flex mb-1 align-items-center user"
    @click.prevent="changeActiveState"
  >
    <div class="user-img img-fluid">
      <img
        :src="photo"
        v-if="hasPhoto"
        class="rounded-circle avatar-sm"
        :alt="fullname"
      >
      <i
        class="las la-user la-3x rounded-circle avatar-sm"
        v-else
      />
    </div>
    <div class="media-support-info ml-3">
      <h6 class="mb-0">
        {{ fullname }}
      </h6>
      <p class="mb-0 font-size-12">
        {{ telephone }}
      </p>
    </div>
    <div class="checking">
      <div
        class="circle"
        :class="active? 'active': ''"
      >
        <div
          class="checkmark"
          v-if="active"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
    props: {
      user:  {type: Object, required: true},
      group: {type: Object, required: true}
    },
    watch: {
        selectAll(){
            this.active = this.selectAll
        },
        active(){
            if(this.active) this.addMember({user: this.user.uid, group: this.group.uid})
            else this.deleteMember({user: this.user.uid, group: this.group.uid})
        }
    },
    mounted(){
        let members = this.group.members.map(({user}) => user)
        this.active = members.includes(this.user.uid)
    },  
    data(){
        return {
            active: false
        }
    },
    methods: {
        ...mapMutations({
            addMember: 'group/ADD_MEMBER',
            deleteMember: 'group/DELETE_MEMBER'
        }),
        changeActiveState(){
            this.active = !this.active
        }
    },
    computed: {
        ...mapGetters({
            selectAll: 'group/selectAll'
        }),
        fullname(){
            return (this.user !== null) ? this.user.fullname: '-'
        },
        telephone(){
            return (this.user !== null)? this.user.telephone: '-'
        },
        hasPhoto(){
            if(this.user !== null) return this.user.photo !== null
            return false
        },
        photoCreateur(){
            return  (this.hasPhotoCreateur) ?  this.user.photo : null
        },
        photo(){
            return this.user
        }
    }
}
</script>

<style lang="scss" scoped>
$primary: #8cc63e;
$notactive: #EFEFEF;
$checkmarkColor: white;
$size: 50px;
$checkmarkFat: 3px;
.user{
    padding: 10px;
    &:hover{
        cursor: pointer;
        background-color: #f5f5f5
    }
    .media-support-info{
        flex-grow: 8
    }
}
</style>