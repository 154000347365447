<template>
  <div class="row">
    <div :class="hasTypeSet? 'col-6':'col-12'">
      <div class="form-group">
        <label :for="'type'+index">Paramètre de filtre [{{ index }}]</label>
        <select
          :disabled="saved"
          v-model="type"
          v-select="{placeholder: 'Selectionnez le filtre'}"
          :name="'type'+index"
          :id="'type'+index"
          class="form-control"
        >
          <option value="" />
          <option
            v-for="(t, i) in types"
            :value="t.type"
            :key="i"
          >
            {{ t.libelle }}
          </option>
        </select>
      </div>
    </div>
    <div
      class="col-5"
      v-if="hasTypeSet"
    >
      <div
        class="form-group"
        v-if="isTypeCompte"
      >
        <label :for="'type_user'+index"> Type de compte</label>
        <select
          :disabled="saved"
          v-model="value"
          v-select="{placeholder: 'Selectionnez le type de compte'}"
          :name="'type_user'+index"
          :id="'type_user'+index"
          class="form-control"
        >
          <option value="" />
          <option
            v-for="(t, i) in sortedTypeComptes"
            :value="t.uid"
            :key="i"
          >
            {{ t.libelle }}
          </option>
        </select>
      </div>
      <div
        class="form-group"
        v-if="isTypeEspece"
      >
        <label :for="'espece'+index"> Espèce</label>
        <select
          :disabled="saved"
          v-model="value"
          v-select="{placeholder: 'Selectionnez espèce'}"
          :name="'espece'+index"
          :id="'espece'+index"
          class="form-control"
        >
          <option value="" />
          <option
            v-for="(e, i) in sortedEspeces"
            :value="e.uid"
            :key="i"
          >
            {{ e.libelle }}
          </option>
        </select>
      </div>
      <div
        class="form-group"
        v-if="isTypeRace"
      >
        <label :for="'race'+index"> Race</label>
        <select
          :disabled="saved"
          v-model="value"
          v-select="{placeholder: 'Selectionnez race'}"
          :name="'race'+index"
          :id="'race'+index"
          class="form-control"
        >
          <option value="" />
          <option
            v-for="(r, i) in sortedRaces"
            :value="r.uid"
            :key="i"
          >
            {{ r.libelle }}
          </option>
        </select>
      </div>
    </div>
    <div
      class="col-1"
      v-if="hasTypeSet"
      style="padding-top: 40px"
    >
      <a
        href="javascript:void(0)"
        @click.prevent="save"
        v-if="!saved"
      >
        <i class="icofont icofont-save" />
      </a>
        
      <i
        v-else
        class="icofont icofont-check-alt icofont-2x"
        style="color: green"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import {LIST_FILTER, TYPE_TYPE_USER, TYPE_ESPECE, TYPE_RACE} from '../../../constants/group'
import { ADD_GROUP_PARAM } from '../../../graphql/group';
export default {
    props: {
        index: {type: Number, required: true},
        group: {type: Object, required: true}
    },
    data(){
        return {
            type: null,
            value: null,
            saved: false
        }
    },
    watch: {
        updateOberservers: {
            handler(){
                if(this.updateOberservers.type !== null && this.updateOberservers.value !== null)
                    this.updateFiltre(this.updateOberservers)
                else this.removeFiltre(this.updateOberservers)
            },
            deep: true
        }
    },
    methods: {
        ...mapMutations({
            updateFiltre: 'group/UPDATE_FILTRE',
            removeFiltre: 'group/REMOVE_FILTRE'
        }),
        save(){
            let data = {
                type: this.type,
                value: this.value,
                groupe: this.group.uid
            }
            this.$apollo.mutate({
                mutation: ADD_GROUP_PARAM,
                variables: {
					"param": {
                        ...data
                    }
				},
                update: (store, { data  }) => {
                    console.log(data)
				},
            }).then((data) => {
                console.log(data)
                this.removeFiltre(this.index)
                this.saved = true
            }).catch((error) => {
                console.error(error)
            })
        }
    },
    computed: {
        ...mapGetters({
            typeComptes: 'auth/typeComptes',
            especes: 'identification/especes',
            races: 'identification/races'
        }),
        isTypeCompte() {return TYPE_TYPE_USER === this.type }, 
        isTypeEspece(){ return TYPE_ESPECE === this.type} ,
        isTypeRace() { return TYPE_RACE === this.type} ,
        hasTypeSet(){
            return this.types.find(item => item.type === this.type) !== undefined
        },
        sortedTypeComptes(){
            return [...this.typeComptes].sort((a,b) => a.libelle.localeCompare(b.libelle))
        },
        sortedEspeces(){
            return [...this.especes].filter(item => item.active).sort((a,b) => a.libelle.localeCompare(b.libelle))
        },
        sortedRaces(){
            return [...this.races].sort((a,b) => a.libelle.localeCompare(b.libelle))
        },  
        types(){return LIST_FILTER()},
        updateOberservers(){ return {order: this.index, type: this.type, value: this.value}}
    }
}
</script>

<style>

</style>